<template>
  <div id="user">
    <div class="header-content">
      <div class="header">
        <div class="header-text">
          <van-image round width="calc(68rem / 16)" height="calc(68rem / 16)" :src="userInfo.userimg" />
          <div class="text" style="position: relative">
            <div class="new-div">
              <div>
                <span>
                  {{
                    userInfo.username
                    ? userInfo.username
                    : $t("newAdd.noLoginUserName")
                  }}
                </span>
                <!-- <span style="font-size: calc(14rem / 16)" v-if="typeof userInfo.level == 'number'" @click="explain">
                  {{ userInfo.level_title }}
                </span> -->
              </div>
            </div>
            <div v-if="userInfo.id">
              id: &nbsp;{{ userInfo.id ? userInfo.id : "" }}
            </div>
            <div v-if="userInfo.credit" class="credit">
              {{ $t('credit', { __: userInfo.credit || "" }) }}
            </div>
            <div style="
                position: absolute;
                right: 0;
                height: 100%;
                display: flex;
              ">
              <van-icon size="1.3rem" name="setting-o" color="#fff" @click="Personal()" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-money">
      <div class="money_left">
        <div class="left_money">
          <span>{{ verConfig.currency }}</span>
          &nbsp;
          <span>{{ $formatNumber(userInfo.money) }}</span>
        </div>
        <!-- <span class="money_text">{{ $t("My.MemberInformation.balance") }}</span> -->
        <span class="money_text">{{ $t('frozenMoney',{_: verConfig.currency + $formatNumber(userInfo.money_locked)}) }}</span>
      </div>
      <div class="money_right">
        <span @click="addClick">+</span>
      </div>
    </div>
    <div class="grid">
      <van-grid column-num="1" :border="false">
        <van-grid-item :text="$t(item.t)" v-for="item, index in gridArr" :key="index" @click="item.callBack()">
          <div class="grid_item">
            <div class="item">
              <van-image fit="cover" :width="index == 1 ? 'calc(15rem / 16)' : 'calc(20rem / 16)'"
                :height="index == 1 ? 'calc(15rem / 16)' : 'calc(20rem / 16)'" :src="item.url" />
              <span class="item_text" style="font-size: 12px; text-align: center" :style=" index == 1 ?{marginLeft: 'calc(18rem / 16)'} : {}">
                {{ $t(item.t) }}
              </span>
            </div>
            <van-icon name="arrow" color="#232f3e" />
          </div>
        </van-grid-item>
      </van-grid>
    </div>

    <div class="logout">
      <van-button color="#2f3848" block type="default" size="normal" @click="logoutEvent">{{ userInfo.token ?
        $t("My.logout") : $t("newAdd.Login") }}</van-button>
    </div>
    <van-action-sheet v-model="show" :actions="actions" @close="onClose" @select="onSelect" />
    <Tabbar active="user" :language="language" />
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  Dialog,
  Toast,
} from "vant";
import Tabbar from "@/components/Tabbar";
import { REQUEST_API } from "@/http/api"; // 引入封装的axios

import { GET_USER_INFP } from "@/api";

// 设置vant的多语言
import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
// import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhCN from "@/i18n/zh.js";
import thTH from "@/i18n/th.js";
import trTR from "@/i18n/tr.js";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
import { mapGetters } from "vuex";
import { getToken } from "@/utils/tools";
export default {
  data() {
    return {
      country: Cookies.get("language"),
      language: "",
      // userInfo: {
      //   name: '',
      //   code: '',
      //   money: '0',
      //   url: '',
      // },
      money: "",
      test: {
        personal: "Personal information",
        preOrde: "Pre-order record",
        account: "Account details",
        recharge: "Recharge record",
        withdrawal: "Withdrawal record",
        invite: "Invite friends",
        announcement: "Announcement",
        team: "Team report",
        language: "Language setting",
      },
      gridItemArr: [
        {
          id: 1,
          url: require("@/assets/icon/myself/personal-info.png"),
          t: `My.personal`,
          callBack: () => {
            if (getToken()) {
              this.$router.push("PersonalCenter"); // 个人中心跳转-小饶
            } else {
              this.$toast(this.$t("newAdd.NotLogin"));
            }
          },
        },
        // {
        //   id: 2,
        //   url: require('@/assets/Img/myImg/orderList.png'),
        //   t: `My.preOrde`,
        //   callBack: () => {
        //     this.$router.push('order')
        //   },
        // },
        {
          id: 3,
          url: require("@/assets/icon/myself/account.png"),
          // t: `My.account`,
          t: "newAdd.AccountRecord",
          callBack: () => {
            if (getToken()) {
              this.$router.push("AccountData"); // 账户资料跳转-小饶
            } else {
              this.$toast(this.$t("newAdd.NotLogin"));
            }
          },
        },
        {
          id: 4,
          url: require("@/assets/icon/myself/recharge-record.png"),
          t: `My.recharge`,
          callBack: () => {
            if (getToken()) {
              this.$router.push("recharge-list");
            } else {
              this.$toast(this.$t("newAdd.NotLogin"));
            }
          },
        },
        // {
        //   id: 5,
        //   url: require("@/assets/icon/myself/withdraw-record.png"),
        //   t: `My.withdrawal`,
        //   callBack: () => {
        //     if (getToken()) {
        //       this.$router.push("/new-withdraw");
        //     } else {
        //       this.$toast(this.$t("newAdd.NotLogin"));
        //     }
        //   },
        // },
        {
          id: 105,
          url: require("@/assets/icon/myself/withdraw-record.png"),
          t: `My.PersonalInformation.WithdrawalRecord.title`,
          callBack: () => {
            if (getToken()) {
              this.$router.push("/withdraw-list");
            } else {
              this.$toast(this.$t("newAdd.NotLogin"));
            }
          },
        },
        // {
        //   id: 6,
        //   url: require('@/assets/Img/myImg/Invitefriends.png'),
        //   t: `My.invite`,
        //   callBack: () => {
        //     this.$router.push('InviteFriends') // 邀请好友跳转-小饶
        //   },
        // },

        // {
        //   id: 6,
        //   url: require('@/assets/icon/myself/invite.png'),
        //   t: `My.invite`,
        //   callBack: () => {
        //     this.$router.push('InviteFriends')
        //   },
        // },
        {
          id: 7,
          url: require("@/assets/icon/myself/server.png"),
          t: `My.service`,
          callBack: () => {
            // this.$router.push("Announcement"); // 系统公告-小饶
            // this.$api.toService();
            this.$store.dispatch("toService")
          },
        },
        // {
        //   id: 8,
        //   url: require('@/assets/Img/myImg/Teamreport.png'),
        //   t: `My.team`,
        //   callBack: () => {
        //     this.$router.push('TheTeamReport') // 系统公告-小饶
        //   },
        // },
        /**
         * 语言修改
         */
        // {
        //   id: 9,
        //   url: require('../../assets/Img/myImg/language.png'),
        //   t: `My.language`,
        //   callBack: () => {
        //     this.show = true
        //   },
        // },
      ],
      show: false,
      actions: [
        {
          name: this.$t("My.languageButton.english"),
          type: "en",
        },
        {
          name: "中文",
          type: "zh",
        },
        {
          name: "ข้อความ",
          type: "th",
        },
        {
          name: "Türk dili",
          type: "tr",
        },
        // {
        //   name: this.$t('My.languageButton.indonesian'),
        //   type: 'idn',
        // },
        {
          disabled: true,
        },
      ],
      /**
       * new
       */
      levelList: [
        this.$t("Cart.grade.SliverMember"),
        this.$t("Cart.grade.GoldMember"),
        this.$t("Cart.grade.PlatinumMember"),
        this.$t("Cart.grade.DiamondMember"),
      ],
      levelText: "",
      gridArr: [{
        id: 0,
        t: 'newTabbar.userPage.topUp',
        url: require("@/assets/Img/users/grid/add.png"),
        callBack: () => {
          if (getToken()) {
            this.$router.push("new-recharge");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        }
      }, {
        id: 1,
        t: 'newTabbar.userPage.withdraw',
        url: require("@/assets/Img/users/grid/out.png"),
        callBack: () => {
          if (getToken()) {
            this.$router.push("new-withdraw");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        }
      }, {
        id: 2,
        t: 'newTabbar.userPage.bankCard',
        url: require("@/assets/Img/users/grid/bank.png"),
        callBack: () => {
          if (getToken()) {
            this.$router.push("add-bank-card");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        }
      },
      {
        id: 3,
        url: require("@/assets/Img/users/grid/order.png"),
        t: "newAdd.AccountRecord",
        callBack: () => {
          if (getToken()) {
            this.$router.push("AccountData");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        },
      },
      {
        id: 4,
        url: require("@/assets/Img/users/grid/order.png"),
        t: `My.recharge`,
        callBack: () => {
          if (getToken()) {
            this.$router.push("recharge-list");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        },
      },
      {
        id: 5,
        url: require("@/assets/Img/users/grid/order.png"),
        t: `My.PersonalInformation.WithdrawalRecord.title`,
        callBack: () => {
          if (getToken()) {
            this.$router.push("/withdraw-list");
          } else {
            this.$toast(this.$t("newAdd.NotLogin"));
          }
        },
      }]
    };
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Dialog.name]: Dialog,
    Tabbar,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    addClick() {
      if (getToken()) {
        this.$router.push("new-recharge");
      } else {
        this.$toast(this.$t("newAdd.NotLogin"));
      }
    },
    //会员说明
    explain() {
      this.$router.push({ name: "levelUpgrade" });
    },
    handleLinkBill() {
      this.$router.push("bill-record"); // 个人中心跳转-小饶
    },
    onSelect(e) {
      console.log(e.type);
      this.country = e.type;
      switch (e.type) {
        case "en":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "idn":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "zh":
          this.$i18n.locale = e.type;
          Locale.use("zh-CN", zhCN);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "th":
          this.$i18n.locale = e.type;
          Locale.use("th-TH", thTH);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
        case "tr":
          this.$i18n.locale = e.type;
          Locale.use("tr-TR", trTR);
          Cookies.set("language", e.type);
          localStorage.setItem("language", e.type);
          break;
      }
      this.language = e.type;
      this.show = false;
      /**
       * new
       */
      this.levelList = [
        this.$t("Cart.grade.SliverMember"),
        this.$t("Cart.grade.GoldMember"),
        this.$t("Cart.grade.PlatinumMember"),
        this.$t("Cart.grade.DiamondMember"),
      ];
      let level =
        JSON.parse(localStorage.getItem("user-info"))[1].data.player.level || 1;
      this.levelList.forEach((_, i) => {
        if (level === i + 1) {
          this.levelText = _;
        }
      });
    },
    // 顶部导航栏的设置事件-小饶
    Personal() {
      if (getToken()) {
        this.$router.push("PersonalCenter");
      } else {
        this.$toast(this.$t("newAdd.NotLogin"));
      }
    },
    onClose() {
      this.show = false;
    },
    // 点击充值的按钮
    RechargeEvent() {
      this.$router.push("new-recharge");
    },
    // 点击提现按钮
    WithdrawEvent() {
      this.$router.push({ name: "newWithdraw" });
    },
    // 注销的按钮
    logoutEvent() {
      let _this = this;
      if (_this.userInfo.token) {
        Dialog.confirm({
          title: this.$t("My.quit"),
          message: this.$t("My.logoutEvent"),
          cancelButtonText: this.$t("My.Button.One"),
          confirmButtonText: this.$t("My.Button.Two"),
        })
          .then(() => {
            let pwdState = _this.$cookie.get("the-pwd-state");
            let pwdObj = _this.$cookie.get("the-pwd-obj");
            _this.$cookie.clear();
            _this.$cookie.set("the-pwd-state", pwdState);
            _this.$cookie.set("the-pwd-obj", pwdObj);
            _this.$router.push("/login");
            // window.location.reload()
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push("/login");
      }
    },
    // 获取账户余额
    BalanceEvent() {
      let form = {
        cmd: "202445",
        uid: this.module.UIDEvent(),
      };
      REQUEST_API(form).then((res) => {
        this.$store.commit("SET_MONEY_INFO", res.data.notifyList[0].data);
        // this.money = res.data.notifyList[0].data.diamond
        this.money = deciTurnMoney(this.$store.state.user.moneyObj.diamond);
      });
    },
    // 获取用户信息
    // getUserInfo() {
    //   // if (this.$cookie.get('userInfo')) {
    //   //   this.userInfo = JSON.parse(this.$cookie.get('userInfo'))
    //   // }
    //   Toast.loading({
    //     forbidClick: true,
    //     duration: 0,
    //   })
    //   this.$api.getUserInfo(res => {
    //     Toast.clear();
    //     this.userInfo = res;
    //   }, err => {
    //     Toast.clear();
    //   });
    // },
    // getUserInfo2() {
    //   GET_USER_INFP()
    //     .then((r) => {
    //       if (r.data.ret === 1) {
    //         this.$cookie.set('userInfo', JSON.stringify(r.data.data))
    //         this.$cookie.set('token', r.data.data.token)
    //         this.userInfo = r.data.data
    //       }
    //     })
    //     .catch((e) => {
    //       console.error(e)
    //     })
    // },
    getDownloadUrl() {
      let url = {};
      let down = {
        id: 5,
        url: require("@/assets/icon/myself/download.png"),
        t: `My.download`,
        callBack: () => {
          window.location.href = url.android;
        },
      };
      this.$api.getNotice((res) => {
        if (res.appurl_android) {
          url.android = res.appurl_android;
          this.gridItemArr.push(down);
        }
      });
    },
  },
  created() {
    // this.getUserInfo()
    this.$store.dispatch('getUserInfo');
    this.getDownloadUrl();
    this.$store.dispatch("updateTaskUserInfo");
    // this.getUserInfo2()
    // this.BalanceEvent() // 获取账户余额
    // if (this.module.PersonalEvnet()[1].data.player.icon) {
    //   this.userInfo.url =
    //     this.module.PersonalEvnet()[1].data.player.icon
    // } else {
    //   this.userInfo.url = require('@/assets/Img/myImg/head.png')
    // }
    // this.userInfo.name =
    //   this.module.PersonalEvnet()[1].data.player.nickname
    // this.userInfo.code = this.module.PersonalEvnet()[0].data.info.id
    // this.userInfo.money = this.module.PersonalEvnet()[0].data.info.gold
    /**
     * new
     */
    // let level =
    //   JSON.parse(localStorage.getItem('user-info'))[1].data.player.level || 1
    // this.levelList.forEach((_, i) => {
    //   if (level === i + 1) {
    //     this.levelText = _
    //   }
    // })
  },
};
</script>

<style lang="less">
@sizeColor: #232f3e;

#user {
  background-color: #f4f8fb;
  min-height: calc(100vh - (110rem / 16));
  padding: calc(50rem / 16) calc(16rem / 16);
  padding-bottom: calc(10rem / 16);

  .header-content {
    background-color: var(--navBg);
    border-radius: calc(8rem / 16);

    .van-hairline--bottom::after {
      border-bottom-width: 0;
    }

    .header {
      padding: calc(16rem / 16);

      .header-text {
        display: flex;
        flex-direction: row;

        >.van-image {
          width: calc(68rem / 16);
          height: calc(68rem / 16);
          display: block;
          border-radius: 50%;
          float: left;
          border: 0.07rem solid #dddddd;
          box-sizing: border-box;
        }

        .text {
          margin-left: 1.4rem;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          div:nth-child(1) {
            display: flex;
            flex-direction: row;
            align-items: center;

            span:nth-child(1) {
              font-size: 1.25rem;
              line-height: 2rem;
              letter-spacing: .0125em;
              color: #fff;
              display: flex;
              flex-direction: row;
              align-items: center;
            }

            span:nth-child(2) {
              display: block;
              color: #fff;
              border-radius: 1.2rem;
              font-size: 0.6rem;
              line-height: 1.1rem;
              text-align: center;
              margin-left: calc(5rem / 16);
            }
          }

          .new-div {
            // width: calc(90vw - (2.4rem + 1.4rem));
            justify-content: space-between;

            >button {
              color: rgb(255, 255, 255);
              background: rgb(47, 56, 72);
              border-color: rgb(47, 56, 72);
              border: 0;
              border-radius: 5px;
              padding: calc(3rem / 16) calc(12rem / 16);
            }
          }

          div:nth-child(2) {
            clear: both;
            font-size: 0.8rem;
            color: #fff;
            display: block;
            text-align: left;
          }

          .credit {
            color: #fff;
            font-size: calc(14rem / 16);
          }
        }
      }

    }
  }

  .header-money {
    background-color: #fff;
    border-radius: calc(8rem / 16);
    padding: calc(16rem / 16);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .money_left {
      color: @sizeColor;

      .money_text {
        font-size: .75rem;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: .03333em;
        opacity: .7;
      }

      .left_money {
        span {
          font-size: calc(30rem / 16);
          font-weight: 400;
          line-height: 2.5rem;
          letter-spacing: .00735em;
        }
      }

    }

    .money_right {
      span {
        padding: 0 calc(20rem / 16);
        background-color: @sizeColor;
        color: #fff;
        display: inline-block;
        line-height: calc(44rem / 16);
        border-radius: calc(99rem / 16);
        font-size: calc(30rem / 16);
      }
    }
  }

  .grid {
    margin-top: calc(16rem / 16);
    border-radius: calc(8rem / 16);
    overflow: hidden;

    .van-grid-item__content {
      align-items: normal;
      padding: 0 calc(16rem / 16);
    }

    .grid_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: calc(12rem / 16) 0;

      .item {
        display: flex;
        align-items: center;

        .item_text {
          font-size: calc(14rem / 16);
          line-height: calc(24rem / 16);
          margin-left: calc(14rem / 16);
        }
      }

      border-bottom: 1px solid #ebedf0;
    }

    .van-grid-item:last-child .grid_item {
      border-bottom: none;
    }
  }

  .logout {
    margin: 0.95rem 0.8rem;
  }
}</style>
